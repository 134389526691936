import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Card,
  Stack,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AlertMessage from "ui-component/AlertMessage";
import DeleteDialog from "ui-component/delete-dialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useInvestigationRecordService from "services/useInvestigationRecordService";
import { useDialogOpenration } from "context/DialogContex";
import InvestigationReportView from "./InvestigationReportView";
import { convertDateToLocal } from "utils/DateOperations";
import { useSelector } from "react-redux";
const ReportCard = ({
  reportData,
  loadInvestigationRecord,
  loadSelectedReport,
}) => {
  const { role } = useSelector((state) => state.userReducer);
  const theme = useTheme();
  const [openDelete, setOpenDelete] = useState(false);
  const [openTost, setOpenTost] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  //services
  const { deleteInvestigationsRecord } = useInvestigationRecordService();
  const { setOpenDialog, openDialog } = useDialogOpenration();
  const handleCancelDelete = () => {
    setOpenDelete(false);
  };
  const handleDelete = async () => {
    try {
      const report = await deleteInvestigationsRecord(selectedReport.id);
      setOpenTost(true);
      loadInvestigationRecord();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (openDialog == false) {
      setViewReport(false);
      loadSelectedReport();
    }
  }, [openDialog]);
  const handleDialogClose = () => {
    setViewReport(false);
    setOpenDialog(false);
    loadSelectedReport();
  };
  return (
    <>
      <Grid container spacing={2} marginTop={1}>
        {Array.isArray(reportData) &&
          reportData.map((row, index) => (
            <Fragment key={index}>
              <Grid item xs={12} md={4}>
                <Card
                  variant="outlined"
                  sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <Typography fontWeight="bold">{row.title}</Typography>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography fontWeight="bold">Report Date</Typography>
                      <Typography>{convertDateToLocal(row.date)}</Typography>
                    </Stack>

                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <>
                        <Tooltip title="View Report">
                          <IconButton
                            onClick={() => {
                              setSelectedReport(row);
                              setViewReport(true);
                            }}
                            color="primary"
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Edit Report Details"
                          sx={{ display: role == 7 ? "none" : "block" }}
                        >
                          <IconButton
                            onClick={() => {
                              loadSelectedReport(row);
                              setOpenDialog(true);
                            }}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title="Delete"
                          sx={{ display: role == 7 ? "none" : "block" }}
                        >
                          <IconButton
                            onClick={() => {
                              setSelectedReport(row);
                              setOpenDelete(true);
                            }}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Fragment>
          ))}
      </Grid>
      <Dialog
        open={viewReport}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <InvestigationReportView
            selectedReport={selectedReport}
            handleDialogClose={handleDialogClose}
          />
        </DialogContent>
      </Dialog>

      <AlertMessage
        opendeleteTost={openTost}
        message="Report Deleted succesfully!!"
      />
      <DeleteDialog
        openDeleteDlg={openDelete}
        setOpenDeleteDlg={setOpenDelete}
        handleDelete={handleDelete}
        componentname="card"
      />
    </>
  );
};

export default ReportCard;
