import React, { useContext, useEffect, useState } from "react";
import PrintReportContext from "./ReportContext";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import ReportSingleComponent from "./ReportSingleComponent";
import ReportPhotos from "../ReportPhotos";
import useLaproscopyPhotosServices from "services/useLaproscopyPhotosServices";
import useHysterolaproscopyPhotosServices from "services/useHysterolaproscopyPhotosServices";

const ReportCardData = ({ id, printStatus }) => {
  const [reportPhtos, setReportPhotos] = useState();
  const { getLaproscopyPhotoByScanReportId } = useLaproscopyPhotosServices();
  const { getHysterolaproscopyPhotoByScanReportId } =
    useHysterolaproscopyPhotosServices();

  const {
    selectedReport,
    reportType,
    reportName,
    reportId,
    doctorsdata,
    patientname,
    theme,
    isSmallScreen,
  } = useContext(PrintReportContext);
  useEffect(() => {
    if (selectedReport) {
    }
  }, [selectedReport]);
  useEffect(() => {
    if (reportType == 0) {
      getLaproscopyPhotoByScanReportId(selectedReport.id)
        .then((response) => {
          setReportPhotos(response.data.laproscopyPhotos);
        })
        .catch((err) => console.log(err));
    } else {
      getHysterolaproscopyPhotoByScanReportId(selectedReport.id)
        .then((response) => {
          setReportPhotos(response.data.hysterolaproscopyPhotos);
        })
        .catch((err) => console.log(err));
    }
  }, [reportType]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="cervix"
            data={selectedReport?.cervixDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label="cervialCanal"
            data={selectedReport?.cervialCanalDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="uterineCavity"
            data={selectedReport?.uterineCavityDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="biCornue"
            data={selectedReport?.biCornueDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="endometrium"
            data={selectedReport?.endometriumDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="uterus"
            data={
              selectedReport?.uterus != undefined
                ? selectedReport?.uterus
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="overy"
            data={
              selectedReport?.overy != undefined ? selectedReport?.overy : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label="rightOvery"
            data={
              selectedReport?.rightOvery != undefined
                ? selectedReport?.rightOvery
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="leftOvery"
            data={
              selectedReport?.leftOvery != undefined
                ? selectedReport?.leftOvery
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="tubes"
            data={
              selectedReport?.tubes != undefined ? selectedReport?.tubes : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label="tubalPatencyTest"
            data={
              selectedReport?.tubalPatencyTest != undefined
                ? selectedReport?.tubalPatencyTest
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label="comments"
            data={selectedReport?.comments}
          />
        </Grid>

        {printStatus == false ? (
          <Grid item xs={12} sm={12}>
            <ReportPhotos label="Photos" photos={reportPhtos} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default ReportCardData;
