import React, { useContext, useState, useEffect, useRef } from "react";
import PrintReportContext from "./ReportContext";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import BackPageIcon from "@mui/icons-material/ArrowBack";
//Third Party Imports
import { useReactToPrint } from "react-to-print";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import ReportCardData from "./ReportCardData";
import PrintReport from "./PrintReport";
import calculateAge from "utils/calculateAge";
const ReportContentDoctors = () => {
  const {
    selectedReport,
    reportType,
    reportName,
    reportId,
    doctorsdata,
    patientname,
    theme,
    handleDialogClose,
  } = useContext(PrintReportContext);
  //create Referance
  const componentRef = useRef();

  const [printCmd, setPrintCmd] = useState(false);
  useEffect(() => {
    if (printCmd) {
      handlePrint();
    }
  }, [printCmd]);
  const onPrintComplete = () => {
    setPrintCmd(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintComplete,
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{ marginTop: 2, backgroundColor: theme.palette.grey[50] }}
      >
        <CardHeader
          title={
            <>
              <Typography variant="h3" color="primary">
                {reportType == 0 ? "Laproscopy" : "Hysterolaproscopy"} Report
              </Typography>
              <Grid item sx={{ mt: 1, display: { sm: "block", xs: "none" } }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.patientMaster.salutationDisplay +
                      " " +
                      patientname?.patientMaster.firstName +
                      " " +
                      patientname?.patientMaster.middleName +
                      " " +
                      patientname?.patientMaster.lastName}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.uhid}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.patientMaster.genderDisplay}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {calculateAge(
                      patientname?.patientMaster.birthDate
                    ).toString() + " yrs"}
                  </Typography>
                </Stack>
              </Grid>
            </>
          }
          action={
            <Stack direction="row" spacing={1}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<LocalPrintshopRoundedIcon />}
                onClick={() => {
                  setPrintCmd(true);
                }}
              >
                Print
              </Button>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<BackPageIcon />}
                onClick={() => {
                  handleDialogClose();
                }}
              >
                Back
              </Button>
            </Stack>
          }
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item sx={{ mt: 1, display: { sm: "none", xs: "block" } }}>
              <Stack spacing={1}>
                <Typography variant="h4">
                  {patientname?.patientMaster.salutationDisplay +
                    " " +
                    patientname?.patientMaster.firstName +
                    " " +
                    patientname?.patientMaster.middleName +
                    " " +
                    patientname?.patientMaster.lastName}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {" "}
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.uhid}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.patientMaster.genderDisplay}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {calculateAge(
                      patientname?.patientMaster.birthDate
                    ).toString() + " yrs"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item sm={12} sx={{ mt: 1 }}>
              <ReportCardData ref={componentRef} printStatus={printCmd} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div style={{ display: "none" }}>
        <PrintReport ref={componentRef} />
      </div>
    </>
  );
};

export default ReportContentDoctors;
