import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  Fragment,
  useContext,
} from "react";
import {
  Grid,
  Stack,
  Card,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
  Snackbar,
  Dialog,
  DialogContent,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteDialog from "./DeleteDialog";
import MuiAlert from "@mui/material/Alert";
import AlertMessage from "ui-component/AlertMessage";
import ReportDoctors from "./report-print";
import useLummellaPhotosServices from "services/useLummellaPhotosServices";
import useLaproscopyPhotosServices from "services/useLaproscopyPhotosServices";
import useHysterolaproscopyPhotosServices from "services/useHysterolaproscopyPhotosServices";
import { convertDateToLocal } from "utils/DateOperations";
import { useSelector } from "react-redux";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ReportCard = ({ loadAllReportData, reportData }) => {
  const { role } = useSelector((state) => state.userReducer);
  const theme = useTheme();
  const navigate = useNavigate();
  //use context here for getting data from index.js

  const [searchParams] = useSearchParams();
  const [selectedReport, setSelectedReport] = useState();
  const [reportName, setReportName] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [reportPhoto, setReportPhoto] = useState();
  const [opentoast, setOpenToast] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [reportType, setReportType] = useState();
  //services

  const { addLaproscopyReportPhoto } = useLaproscopyPhotosServices();

  const { addHysterolaproscopyReportPhoto } =
    useHysterolaproscopyPhotosServices();

  const { addLummellaReportPhoto } = useLummellaPhotosServices();
  //adding single photo from card
  useEffect(() => {
    if (reportPhoto == undefined) {
      return;
    }
    const fd = new FormData();
    setUploading(true);

    if (selectedReport && reportPhoto && reportName) {
      fd.append("file", reportPhoto);

      if (reportName.toLowerCase() == "laproscopy") {
        addLaproscopyReportPhoto(selectedReport?.id, fd)
          .then((response) => {
            if (response.status === 200) {
              setOpenToast(true);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setUploading(false);
          });
      } else if (reportName.toLowerCase() == "hysterolaproscopy") {
        addHysterolaproscopyReportPhoto(selectedReport?.id, fd)
          .then((response) => {
            if (response.status === 200) {
              setOpenToast(true);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setUploading(false);
          });
      } else if (reportName.toLowerCase() == "lummella") {
        addLummellaReportPhoto(selectedReport?.id, fd)
          .then((response) => {
            if (response.status === 200) {
              setOpenToast(true);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setUploading(false);
          });
      }
    }
  }, [reportPhoto, selectedReport]);
  const handleCancelDelete = () => {
    setOpenDelete(false);
    setOpenToast(true);
  };
  const handleEdit = (data, name) => {
    if (!name) {
      return;
    }
    if (name.toLowerCase() === "laproscopy") {
      navigate(`/laproscopy-form?reportid=${data.id}`);
    }
    if (name.toLowerCase() === "hysterolaproscopy") {
      navigate(`/hysterolaproscopy-form?reportid=${data.id}`);
    }
    if (name.toLowerCase() === "lummella") {
      navigate(`/lummella-form?reportid=${data.id}`);
    }
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleUpload = (data, name) => {
    setReportName(name);
    setSelectedReport(data);
  };
  const viewReport = (id, reportname) => {
    if (!id && !reportname) {
      return;
    }
    window.open(
      `/print-doctorsreport?reportid=${id}&name=${reportname}`,
      "_blank"
    );
  };
  const handleDelete = (data, name) => {
    setSelectedReport(data);
    setReportName(name);
    setOpenDelete(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid container spacing={2} marginTop={5}>
        {reportData.map((row, index) => (
          <Fragment key={index}>
            {Array.isArray(row.data) &&
              row.data.map((d, i) => (
                <Grid item xs={12} md={4}>
                  <Card
                    variant="outlined"
                    sx={{
                      padding: 2,
                      backgroundColor: theme.palette.primary.light,
                    }}
                  >
                    <Stack spacing={2}>
                      <Stack spacing={1}>
                        <Typography fontWeight="bold">
                          {row?.name} Report
                        </Typography>
                        <Typography>{d?.generalImpression}</Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography fontWeight="bold">Report Date</Typography>
                        <Typography>
                          {convertDateToLocal(d.dischargeDate)}
                        </Typography>
                      </Stack>
                      {uploading &&
                      d?.id == selectedReport?.id &&
                      reportName.toLowerCase() == row?.name.toLowerCase() ? (
                        <Stack spacing={1}>
                          <Typography>Uploading file... </Typography>
                          <LinearProgress />
                        </Stack>
                      ) : (
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <>
                            {/* <label htmlFor="upload">
                              <input
                                id="upload"
                                name="upload"
                                style={{ display: "none" }}
                                type="file"
                                multiple={false}
                                accept="application/pdf, image/png, image/jpg, image/jpeg"
                                onChange={(e) => {
                                  console.log("files", e.target.files[0]);
                                  // setSelectedReport(d);
                                  setReportPhoto(e?.target?.files[0]);
                                }}
                              />

                              <Tooltip
                                title={
                                  d?.isFileUploaded
                                    ? "Change Report File"
                                    : "Upload Report"
                                }
                              >
                                <IconButton component="span" color="primary">
                                  <UploadFileIcon
                                    onClick={() => {
                                      handleUpload(d, row?.name);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label> */}
                            <Tooltip title="View Report">
                              <IconButton
                                // disabled={data.isFileUploaded == false ? true : false}
                                onClick={() => {
                                  setOpenDialog(true);
                                  setSelectedReport(d);
                                  row.name.toLowerCase() == "laproscopy"
                                    ? setReportType(0)
                                    : setReportType(1);
                                  // viewReport(d.id, row.name);
                                }}
                                color="primary"
                              >
                                <OpenInNewIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Edit Report Details"
                              sx={{ display: role == 7 ? "none" : "block" }}
                            >
                              <IconButton
                                onClick={() => {
                                  handleEdit(d, row.name);
                                }}
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Delete"
                              sx={{ display: role == 7 ? "none" : "block" }}
                            >
                              <IconButton
                                onClick={() => handleDelete(d, row.name)}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        </Stack>
                      )}
                    </Stack>
                  </Card>
                </Grid>
              ))}
          </Fragment>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose} fullScreen>
        <DialogContent>
          <ReportDoctors
            selectedReport={selectedReport}
            reportType={reportType}
            handleDialogClose={handleDialogClose}
          />
        </DialogContent>
      </Dialog>

      <AlertMessage message=" Report Uploaded succesfully!!" />
      {reportName && (
        <DeleteDialog
          openDelete={openDelete}
          handleCancelDelete={handleCancelDelete}
          selectedReport={selectedReport}
          reportName={reportName}
          loadAllReportData={loadAllReportData}
        />
      )}
    </>
  );
};

export default ReportCard;
