import React, { createContext, useContext, useState } from "react";

const DialogContext = createContext();
const DialogContextProvider = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    return setOpenDialog(true);
  };
  const handleDialogClose = () => {
    return setOpenDialog(false);
  };

  return (
    <DialogContext.Provider
      value={{ openDialog, handleDialogClose, handleOpenDialog, setOpenDialog }}
    >
      {children}
    </DialogContext.Provider>
  );
};
const useDialogOpenration = () => {
  return useContext(DialogContext);
};

export { useDialogOpenration, DialogContextProvider };
