
import useAxiosAuth from '../hooks/useAxiosAuth';
import { handleResponse, handleError } from './response';

const useGrowthAltraSoundScanService = () => {
    const axiosAuth = useAxiosAuth();

    const addGrowthAltraSoundScanReport = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/GrowthUltrasoundScans`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/GrowthUltrasoundScans/by-patient-id?patientId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getByScanReportId = async (reportId) => {
        try {
            const response = await axiosAuth.get(`/api/GrowthUltrasoundScans/${reportId}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateGrowthAltraSoundScanRecord = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/GrowthUltrasoundScans?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteGrowthAltraSoundScanRecord = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/GrowthUltrasoundScans/delete?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        addGrowthAltraSoundScanReport,
        getById,
        getByScanReportId,
        updateGrowthAltraSoundScanRecord,
        deleteGrowthAltraSoundScanRecord
    };
};

export default useGrowthAltraSoundScanService;
