import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useHysterolaproscopyServices = () => {
  const axiosAuth = useAxiosAuth();

  const addHysterolaproscopyReport = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Hysterolaproscopies`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getHysterolaproscopyReportByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/Hysterolaproscopies/by-patient-id?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getHysterolaproscopyReportByReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/Hysterolaproscopies/${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateHysterolaproscopyReport = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/Hysterolaproscopies?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteHysterolaproscopyRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/Hysterolaproscopies/delete?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addHysterolaproscopyReport,
    getHysterolaproscopyReportByPatientId,
    getHysterolaproscopyReportByReportId,
    updateHysterolaproscopyReport,
    deleteHysterolaproscopyRecord,
  };
};

export default useHysterolaproscopyServices;
