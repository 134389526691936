import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import PrintReportContext from "./ReportContext";
const ReportSingleComponent = ({ label, data, id }) => {
  const { isSmallScreen, theme } = useContext(PrintReportContext);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h5">{label}:</Typography>
        </Grid>

        <Grid item xs={6} sm={6}>
          {typeof data === "boolean" ? (
            data == true ? (
              <Typography>Yes</Typography>
            ) : (
              <Typography>No</Typography>
            )
          ) : (
            <Typography>{data == "" ? "---" : data}</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ReportSingleComponent;
