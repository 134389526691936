// assets
import { IconUsers, IconBed, IconBookUpload, IconHistory } from "@tabler/icons";

// constant
const icons = {
  IconUsers,
  IconBed,
  IconBookUpload,
  IconHistory,
};

// ==============================|| IPDSTAFF MENU ITEMS ||============================== //

const patientMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "patient-basic-profile",
        title: "My Profile",
        type: "item",
        url: "/basic-profile",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
      {
        id: "patient-all-appointments",
        title: "My Appointments History",
        type: "item",
        url: "/appointment-history",
        icon: icons.IconHistory,
        breadcrumbs: false,
      },
      {
        id: "patient-appointment-booking",
        title: "Book Appointment",
        type: "item",
        url: "/appointment-booking",
        icon: icons.IconBookUpload,
        breadcrumbs: false,
      },
    ],
  },
];

export default patientMenuItems;
