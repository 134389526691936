import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { BASE_URL } from "services/apiBase";
const ReportPhotos = ({ label, photos }) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{label}:</Typography>
        </Grid>
        <Grid container spacing={2}>
          {Array.isArray(photos) &&
            photos.map((data) => {
              return (
                <Grid item xs={6}>
                  <img
                    src={BASE_URL + "/Documents/" + data.filePath}
                    alt="img"
                    width="100%"
                    height="200px"
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
};

export default ReportPhotos;
