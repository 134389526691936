import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useInvestigationRecordService = () => {
  const axiosAuth = useAxiosAuth();

  const getInvestigationsRecordList = async () => {
    try {
      const response = await axiosAuth.get(`/api/InvestigationRecords`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationsRecordListByAppointmentId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationRecords/by-filter?appointmentId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationsRecordListByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationRecords/by-filter?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationsRecordById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/InvestigationRecords/${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addInvestigationsRecord = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/InvestigationRecords`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateInvestigationsRecord = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationRecords?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteInvestigationsRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/InvestigationRecords?id=${id}`
      );

      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    getInvestigationsRecordList,
    getInvestigationsRecordListByAppointmentId,
    getInvestigationsRecordListByPatientId,
    getInvestigationsRecordById,
    addInvestigationsRecord,
    updateInvestigationsRecord,
    deleteInvestigationsRecord,
  };
};

export default useInvestigationRecordService;
