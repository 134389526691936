import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useLummellaServices = () => {
  const axiosAuth = useAxiosAuth();

  const addLummellaReport = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Lummella`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getLummellaReportByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/Lummella/by-patient-id?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getLummellaReportByReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(`/api/Lummella/${reportId}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateLummellaReport = async (id, values) => {
    try {
      const response = await axiosAuth.put(`/api/Lummella?id=${id}`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteLummellaRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/Lummella/delete?id=s${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addLummellaReport,
    getLummellaReportByPatientId,
    getLummellaReportByReportId,
    updateLummellaReport,
    deleteLummellaRecord,
  };
};

export default useLummellaServices;
