import React, { useContext, useState, useEffect, useRef } from "react";
import PrintReportContext from "./ReportContext";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import BackPageIcon from "@mui/icons-material/ArrowBack";
//Third Party Imports
import { useReactToPrint } from "react-to-print";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import ReportCardData from "./ReportCardData";
import PrintReport from "./PrintReport";
import calculateAge from "utils/calculateAge";
const ReportContent = () => {
  const {
    reportdata,
    reportName,
    reportId,
    doctorsdata,
    patientname,
    theme,
    handleDialogClose,
  } = useContext(PrintReportContext);
  //create Referance
  const componentRef = useRef();

  const [printCmd, setPrintCmd] = useState(false);
  useEffect(() => {
    if (printCmd) {
      handlePrint();
    }
  }, [printCmd]);
  const onPrintComplete = () => {
    setPrintCmd(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintComplete,
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{ marginTop: 2, backgroundColor: theme.palette.grey[50] }}
      >
        <CardHeader
          title={
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Typography variant="h3" color="primary">
                {reportdata.type == 1
                  ? "Viablity Scan "
                  : reportdata.type == 2
                  ? "NT Scan (Nuchal Translucency) "
                  : reportdata.type == 3
                  ? "Growth scan "
                  : reportdata.type == 4
                  ? "Anomaly scan "
                  : reportdata.type == 5
                  ? "Colour Doppler "
                  : ""}
                Report
              </Typography>
              <Grid
                item
                xs={12}
                sx={{ mt: 1, display: { sm: "block", xs: "none" } }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.patientMaster.salutationDisplay +
                      " " +
                      patientname?.patientMaster.firstName +
                      " " +
                      patientname?.patientMaster.middleName +
                      " " +
                      patientname?.patientMaster.lastName}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.uhid}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.patientMaster.genderDisplay}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {calculateAge(
                      patientname?.patientMaster.birthDate
                    ).toString() + " yrs"}
                  </Typography>
                </Stack>
              </Grid>
            </Box>
          }
          action={
            <Stack
              direction="row"
              columnGap={2}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<LocalPrintshopRoundedIcon />}
                onClick={() => {
                  setPrintCmd(true);
                }}
              >
                Print
              </Button>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<BackPageIcon />}
                onClick={() => {
                  handleDialogClose();
                }}
              >
                Back
              </Button>
            </Stack>
          }
        />
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Typography variant="h3" color="primary" align="center">
            {reportdata.type == 1
              ? "Viablity Scan "
              : reportdata.type == 2
              ? "NT Scan (Nuchal Translucency) "
              : reportdata.type == 3
              ? "Growth scan "
              : reportdata.type == 4
              ? "Anomaly scan "
              : reportdata.type == 5
              ? "Colour Doppler "
              : ""}
            Report
          </Typography>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} alignSelf="right">
              <Stack direction="row" columnGap={1} mt={5} mb={3}>
                <Button
                  // fullWidth
                  variant="contained"
                  endIcon={<LocalPrintshopRoundedIcon />}
                  onClick={() => {
                    setPrintCmd(true);
                  }}
                >
                  Print
                </Button>
                <Button
                  // fullWidth
                  variant="outlined"
                  startIcon={<BackPageIcon />}
                  onClick={() => {
                    handleDialogClose();
                  }}
                >
                  Back
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              sx={{ mt: 1, display: { sm: "none", xs: "block" } }}
              xs={12}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {patientname?.patientMaster.salutationDisplay +
                    " " +
                    patientname?.patientMaster.firstName +
                    " " +
                    patientname?.patientMaster.middleName +
                    " " +
                    patientname?.patientMaster.lastName}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {" "}
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.uhid}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {patientname?.patientMaster.genderDisplay}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.grey[500] }}
                    fontWeight="medium"
                  >
                    {calculateAge(
                      patientname?.patientMaster.birthDate
                    ).toString() + " yrs"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <ReportCardData ref={componentRef} printStatus={printCmd} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div style={{ display: "none" }}>
        <PrintReport ref={componentRef} />
      </div>
    </>
  );
};

export default ReportContent;
