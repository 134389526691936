import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  LinearProgress,
  Tabs,
  Tab,
} from "@mui/material";
import NoReport from "../../../../ui-component/formcomponent/NoReport";
import useGrowthAltraSoundScanService from "services/useGrowthAltrasoundScanService";
import ReportCard from "./ReportCard";
import useSonographyService from "services/useSonographyReport";
import useViabilityReportService from "services/useViabilityReport";
import Comparison from "./Comparison";
import { useSelector } from "react-redux";
import Hidden from "@mui/material/Hidden";
import MobileReport from "./mobile-view/MobileReport";
const ExternalReport = () => {
  const { role, profileId } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const { getById } = useGrowthAltraSoundScanService();
  const { getSonographyByPatientId } = useSonographyService();
  const { getViabilityReportByPatientId } = useViabilityReportService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [reportData, setReportData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [reportType, setReportType] = useState(1);
  const OpenUltraSoundForm = () => {
    navigate(
      `/viability-form?pateintId=${searchParams.get("Id")}&type=${reportType}`
    );
  };
  const loadData = async () => {
    try {
      let id = 0;
      if (role == 7) {
        id = profileId;
      } else {
        id = searchParams.get("Id");
      }
      const resultsonography = await getSonographyByPatientId(id, reportType);

      setReportData(resultsonography.data?.sonographies);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    // if (reportType) {
    loadData();
    // }
  }, [reportType]);
  const handleTabChange = (e, newVal) => {
    setTabValue(newVal);
  };
  useEffect(() => {
    if (tabValue == 0) {
      setReportType(1);
    } else if (tabValue == 1) {
      setReportType(3);
    } else if (tabValue == 2) {
      setReportType(5);
    }
  }, [tabValue]);

  return (
    <>
      <Grid container direction="column">
        <Grid item sm={6} xs={6}>
          <Typography align="left" variant="h4" color="secondary" mt={2}>
            External Reports
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="First Trimester"></Tab>
          <Tab label="Second trimester"></Tab>
          <Tab label="Third trimester"></Tab>
          <Tab label="Compare All"></Tab>
        </Tabs>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileReport reportData={reportData} loadData={loadData} />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        <Grid item align="left" xs={9}>
          {tabValue == 0 && (
            <>
              <Stack mt={2} columnGap={2} direction="row">
                <Button
                  variant={reportType == 1 ? "contained" : "outlined"}
                  onClick={() => {
                    setReportType(1);
                  }}
                >
                  Viability scan
                </Button>
                <Button
                  variant={reportType == 2 ? "contained" : "outlined"}
                  onClick={() => {
                    setReportType(2);
                  }}
                >
                  NT Scan
                </Button>
              </Stack>
            </>
          )}
          {tabValue == 1 && (
            <Stack mt={2} columnGap={2} direction="row">
              <Button
                variant={reportType == 3 ? "contained" : "outlined"}
                onClick={() => {
                  setReportType(3);
                }}
              >
                Growth scan
              </Button>
              <Button
                variant={reportType == 4 ? "contained" : "outlined"}
                onClick={() => {
                  setReportType(4);
                }}
              >
                Anomaly scan{" "}
              </Button>
            </Stack>
          )}
          {tabValue == 2 && (
            <Stack mt={2} columnGap={2} direction="row">
              <Button
                variant={reportType == 5 ? "contained" : "outlined"}
                onClick={() => {
                  setReportType(5);
                }}
              >
                Colour Doppler
              </Button>
            </Stack>
          )}
          {tabValue == 3 && (
            <Comparison
              patientId={role == 7 ? profileId : searchParams.get("Id")}
            />
          )}
        </Grid>
        {tabValue != 3 ? (
          <Grid
            item
            align="right"
            xs={12}
            sx={{ display: role == 7 ? "none" : "block" }}
          >
            <Button onClick={OpenUltraSoundForm} variant="contained">
              Add New Report
            </Button>
          </Grid>
        ) : null}
      </Stack>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {Array.isArray(reportData) && reportData.length > 0 && tabValue != 3 ? (
          <ReportCard reportData={reportData} loadData={loadData} />
        ) : (
          <>{tabValue != 3 ? <NoReport /> : null}</>
        )}
      </Box>
      {/* <Box sx={{ display: { xs: "table", sm: "none" } }}>
        <Typography variant="h4" color="primary" mt={2}>
          Comparision
        </Typography>
        <Comparison
          patientId={role == 7 ? profileId : searchParams.get("Id")}
        />
      </Box> */}
    </>
  );
};

export default ExternalReport;
