import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import NoReport from "ui-component/formcomponent/NoReport";
import { convertDateToLocal } from "utils/DateOperations";

const useStyles = makeStyles({
  fix: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "white", // Set background color to match your table's background
  },
});

const Comparision = ({ reportData }) => {
  const theme = useTheme();
  const classes = useStyles();

  const filteredObjects =
    Array.isArray(reportData) && reportData.filter((obj) => obj.type === 0);
  return (
    <>
      {" "}
      <Typography variant="h4" sx={{ display: { xs: "none", sm: "block" } }}>
        Sonography Comparision
      </Typography>
      {filteredObjects?.length > 0 ? (
        <TableContainer sx={{ mt: 2 }}>
          <Table
            className={classes.table}
            size="small"
            sx={{
              border: "solid",
              borderWidth: 1,
              borderColor: "divider",
            }}
          >
            <>
              <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                <TableRow>
                  <TableCell className={classes.fix}></TableCell>
                  {filteredObjects
                    ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map((row) => (
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Typography variant="h5">{row.title}</Typography>
                        <Typography
                          variant="h6"
                          color={theme.palette.grey[500]}
                        >
                          {" "}
                          {convertDateToLocal(row.date)}
                        </Typography>
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Haemoglobin</Typography>
                  </TableCell>
                  {filteredObjects.map((ele) => (
                    <TableCell
                      key={ele.date}
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      {ele.investigationRecordItems[0].value != "" ? (
                        <Typography>
                          {ele.investigationRecordItems[0].value} g/dl
                        </Typography>
                      ) : (
                        <Typography>---</Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Sugar</Typography>
                  </TableCell>
                  {filteredObjects.map((ele) => (
                    <TableCell
                      key={ele.date}
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      {ele.investigationRecordItems[1].value != "" ? (
                        <Typography>
                          {ele.investigationRecordItems[1].value} mg/dl
                        </Typography>
                      ) : (
                        <Typography>---</Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </>
          </Table>
        </TableContainer>
      ) : (
        <NoReport />
      )}
    </>
  );
};

export default Comparision;
