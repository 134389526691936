import axios from "axios";

// export const BASE_URL = "http://192.168.1.18:5051"; //localhost SNEHA
// export const BASE_URL = "https://modiapi.drcodez.com"; //production server
// export const BASE_URL = "https://localhost:7187"; //local
export const BASE_URL = "https://modihmsbetest.agsapplications.com"; //test1 server

export const axiosOpen = axios.create({
  baseURL: BASE_URL,
});

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  headers: { ContentType: "application/json" },

  withCredentials: true,
});

//OPD Revenue
