import React, { createContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useGrowthAltraSoundScanService from "services/useGrowthAltrasoundScanService";
import useSonographyService from "services/useSonographyReport";
import useViabilityReportService from "services/useViabilityReport";
import useUserService from "services/useUserService";
import usePatientsService from "services/usePatientsService";
import ReportContent from "./ReportContent";
import PrintReportContext from "./ReportContext";

const Report = ({ selectedReport, handleDialogClose }) => {
  const theme = useTheme();
  //mediaquery
  const isSmallScreen = useMediaQuery("(max-width:770px)");

  const [searchParams] = useSearchParams();
  //set ReportId
  const [reportId, setReportId] = useState(undefined);
  //set report name
  const [reportName, setReportName] = useState(undefined);

  //save report data in this state
  const [reportdata, setReportData] = useState("");

  //save patien name data
  const [patientname, setPatientName] = useState();

  //save doctors name data
  const [doctorsdata, setDoctorsData] = useState();
  //call service to get data of particular report by report id
  // const { getByScanReportId } = useGrowthAltraSoundScanService();
  const { getBySonographyReportId } = useSonographyService();
  // const { getVaibilityReportByScanReportId } = useViabilityReportService();

  //call service for takeing patient name and report created doctor name
  const { getUserById } = useUserService();
  const { getPatientById } = usePatientsService();

  const getPageMargins = () => {
    return `@page { margin: ${"40px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  useEffect(() => {
    if (searchParams.get("reportid") && searchParams.get("name")) {
      setReportId(searchParams.get("reportid"));
      const str = searchParams.get("name").charAt(0).toUpperCase();
      const modifystr = str + searchParams.get("name").slice(1);
      setReportName(modifystr);
    }
  }, []);
  useEffect(() => {
    if (selectedReport) {
      // if (reportName.toLowerCase() == "sonography") {
      getBySonographyReportId(selectedReport)
        .then((result) => {
          setReportData(result.data);
        })

        .catch((err) => {
          console.log("err", err);
        });
      //   } else if (reportName.toLowerCase() == "growth ultraSound scan") {
      //     getByScanReportId(reportId)
      //       .then((result) => {
      //         setReportData(result.data);
      //       })

      //       .catch((err) => {
      //         console.log("err", err);
      //       });
      //   } else if (reportName.toLowerCase() == "viability") {
      //     getVaibilityReportByScanReportId(reportId)
      //       .then((result) => {
      //         setReportData(result.data);
      //       })
      //       .catch((err) => {
      //         console.log("err", err);
      //       });
      //   }
    }
  }, [reportId, reportName]);
  useEffect(() => {
    if (!reportdata) {
      return;
    }
    getUserById(reportdata.userId)
      .then((result) => {
        setDoctorsData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getPatientById(reportdata.patientId)
      .then((result) => {
        setPatientName(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reportdata]);
  return (
    <>
      <PrintReportContext.Provider
        value={{
          reportdata,
          reportName,
          reportId,
          doctorsdata,
          patientname,
          theme,
          isSmallScreen,
          handleDialogClose,
        }}
      >
        <ReportContent />
      </PrintReportContext.Provider>
    </>
  );
};

export default Report;
