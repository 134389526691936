import React from "react";

import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const usePatientsService = () => {
  const axiosAuth = useAxiosAuth();

  const getIpdPatientList = async () => {
    try {
      const response = await axiosAuth.get(`/api/Patients/ipdPatients-list`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getFilteredPatientList = async (
    doctorId,
    departmentId,
    type,
    fromDate,
    toDate
  ) => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients/ipd-opd-all-Patients-list?doctorId=${doctorId}&department=${departmentId}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const checkIdIPDPatient = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients/is-ipd-patient?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const searchPatients = async (searchText, department, skip, pageSize) => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients/search?searchText=${searchText}&department=${department}&skip=${skip}&take=${pageSize}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getPatients = async (department) => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients?department=${department}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getPatientById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/Patients/${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getPatientByBarcode = async (barcode) => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients/patient-by-UHID?UHID=${barcode}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getDayCarePatientList = async () => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients/day-care-patients-list`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updatePatient = async (id, values) => {
    try {
      const response = await axiosAuth.put(`/api/Patients?id=${id}`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const softDeletePatient = async (patientId) => {
    try {
      const response = await axiosAuth.delete(
        `/api/Patients/soft-delete?id=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getDeletedPatientPaginated = async (skip, take) => {
    try {
      const response = await axiosAuth.get(
        `/api/Patients/Deleted-Peginated-List?skip=${skip}&take=${take}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const addPatient = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Patients`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deletePatient = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/Patients/Delete?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getIpdPatientList,
    getFilteredPatientList,
    checkIdIPDPatient,
    getPatientByBarcode,
    searchPatients,
    getPatientById,
    softDeletePatient,
    addPatient,
    getDeletedPatientPaginated,
    getDayCarePatientList,
    updatePatient,
    deletePatient,
    getPatients,
  };
};

export default usePatientsService;
