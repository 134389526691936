import React from "react";
import { Grid, Typography, Stack, Button } from "@mui/material";
import ReportCard from "../ReportCard";
import NoReport from "ui-component/formcomponent/NoReport";
const SecoundTrimester = ({
  secoundTrimesterReport,
  setSecoundTrimesterReport,
  secoundTrimesterData,
  loadSeoundTrimesterData,
}) => {
  return (
    <Grid xs={12}>
      <Typography variant="h4" color="primary" mt={2}>
        Second trimester
      </Typography>
      <Stack mt={2} columnGap={2} direction="row">
        <Button
          variant={secoundTrimesterReport == 3 ? "contained" : "outlined"}
          onClick={() => {
            setSecoundTrimesterReport(3);
          }}
        >
          Growth scan
        </Button>
        <Button
          variant={secoundTrimesterReport == 4 ? "contained" : "outlined"}
          onClick={() => {
            setSecoundTrimesterReport(4);
          }}
        >
          Anomaly scan{" "}
        </Button>
      </Stack>
      {Array.isArray(secoundTrimesterData) &&
      secoundTrimesterData.length > 0 ? (
        <ReportCard
          reportData={secoundTrimesterData}
          loadData={loadSeoundTrimesterData}
        />
      ) : (
        <>
          <NoReport />
        </>
      )}
    </Grid>
  );
};

export default SecoundTrimester;
