import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  Divider,
  Select,
  MenuItem,
  TableBody,
  Table,
  TableContainer,
  TableRow,
  Tooltip,
  Button,
  TableHead,
  useTheme,
  TableCell,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
//library import
import { useSearchParams } from "react-router-dom";

//project import
import useInvestigationTempletsMasterService from "services/useInvestigationTempletsMasterService";
import { useDialogOpenration } from "context/DialogContex";
import useInvestigationRecordService from "services/useInvestigationRecordService";
import useAppointmentService from "services/useAppointmentService";
import {
  convertDateToLocal,
  convertDateToLocalReverse,
} from "utils/DateOperations";

const InvestigationRecordForm = ({
  loadInvestigationRecord,
  selectedReport,
  loadSelectedReport,
}) => {
  const theme = useTheme();

  //state
  const [investigationTempleteMaster, setInvestigationTempleteMaster] =
    useState();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [selectedTemplate, setSelectedTemplete] = useState({
    date: convertDateToLocalReverse(new Date()),
  });

  const [reportDate, setReportDate] = useState(
    convertDateToLocalReverse(selectedReport?.date)
  );

  //services
  const { getInvestigationsTempletsMasterList } =
    useInvestigationTempletsMasterService();
  const { addInvestigationsRecord, updateInvestigationsRecord } =
    useInvestigationRecordService();
  const { handleDialogClose } = useDialogOpenration();

  useEffect(() => {
    getInvestigationsTempletsMasterList()
      .then((response) => {
        response.data.investigationTemplets.forEach((ele) => {
          ele.investigationRecordItems = ele.investigationTempletItems;
          delete ele.investigationTempletItems;
        });

        setInvestigationTempleteMaster(response.data.investigationTemplets);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (selectedReport) {
      setSelectedTemplete(selectedReport);
      // setSelectedAppointment(selectedReport.appointment);
    }
  }, [selectedReport]);

  const handleChangeValue = (i, value) => {
    const item = selectedTemplate.investigationRecordItems[i];
    const index = i;
    let newItemArr = [...selectedTemplate.investigationRecordItems];
    item.value = value;
    newItemArr.splice(index, 1, item);
    setSelectedTemplete({
      ...selectedTemplate,
      investigationRecordItems: newItemArr,
    });
  };
  const UpdateErrors = (prop, msg) => {
    // setErrors((state) => ({ ...state, [prop]: msg }));
  };
  const saveData = async () => {
    selectedTemplate.appointmentId = 0;
    selectedTemplate.patientId = searchParams.get("Id");
    selectedTemplate.date = reportDate;
    selectedTemplate.investigationRecordItems.forEach(
      (ele) => (ele.investigationRecordId = selectedTemplate.id)
    );
    selectedTemplate.investigationRecordItems.forEach(
      (ele) => delete ele.investigationTempletId
    );
    setLoading(true);
    if (selectedReport?.id != 0) {
      try {
        const response = await updateInvestigationsRecord(
          selectedTemplate.id,
          selectedTemplate
        );
        if (response.data) {
          setLoading(false);
          handleDialogClose();
          loadInvestigationRecord();
          loadSelectedReport();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const response = await addInvestigationsRecord(selectedTemplate);
        if (response.data) {
          setLoading(false);
          handleDialogClose();
          loadInvestigationRecord();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = investigationTempleteMaster.find(
      (option) => option.title === selectedValue
    );
    selectedOption.date = selectedTemplate.date;
    setSelectedTemplete(selectedOption);
  };

  return (
    <>
      <Grid align="center">
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginX: "auto", alignItems: "center" }}
        >
          <LocalHospitalIcon fontSize="medium" color="primary" />
          <Typography variant="h4">
            {selectedReport?.id != 0 ? "Edit" : "Add"} Investigation Record
          </Typography>
        </Stack>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormLabel>Investigation Templet</FormLabel>
          <TextField
            sx={{ display: selectedReport?.id == 0 ? "block" : "none" }}
            select
            fullWidth
            value={selectedTemplate.title && selectedTemplate.title}
            onChange={handleSelectChange}
            variant="standard"
          >
            {Array.isArray(investigationTempleteMaster) &&
              investigationTempleteMaster.map((option, index) => (
                <MenuItem key={option.number} value={option.title}>
                  {option.title}
                </MenuItem>
              ))}
          </TextField>
          <Typography variant="h5">{selectedReport?.title}</Typography>
        </Grid>

        <Grid item xs={4}>
          <FormLabel>Date</FormLabel>
          <TextField
            fullWidth
            type="date"
            name="date"
            variant="standard"
            value={reportDate}
            onChange={(e) => {
              setReportDate(e.target.value);
            }}
          ></TextField>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: selectedTemplate?.investigationRecordItems
              ? "block"
              : "none",
          }}
        >
          <TableContainer sx={{ mt: 3, mb: 3 }}>
            <Table
              sx={{
                border: "solid",
                borderWidth: 1,
                borderColor: "divider",
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell sx={{ width: 50 }} align="left">
                    #
                  </TableCell>
                  <TableCell sx={{ width: 150 }} align="left">
                    Title
                  </TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(selectedTemplate?.investigationRecordItems) &&
                  selectedTemplate.investigationRecordItems.sort(
                    (a, b) => a.id - b.id
                  ) &&
                  selectedTemplate?.investigationRecordItems.map(
                    (data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography align="left">{index + 1}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography align="left">{data?.title}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <>
                                  {" "}
                                  {data?.unit != "" ? (
                                    <InputAdornment position="end">
                                      {data?.unit}
                                    </InputAdornment>
                                  ) : null}
                                </>
                              ),
                            }}
                            fullWidth
                            variant="standard"
                            value={data.value}
                            onChange={(e) => {
                              handleChangeValue(index, e.target.value);
                            }}
                            onFocus={(event) => {
                              event.target.select();
                            }}
                            helperText={
                              data?.min != "" || data?.max != ""
                                ? `Min: ${data?.min}, Max:${data?.max}`
                                : null
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: selectedTemplate?.investigationRecordItems
              ? "block"
              : "none",
          }}
        >
          <TextField
            label="remark"
            fullWidth
            value={selectedTemplate?.remark}
            onChange={(e) => {
              setSelectedTemplete({
                ...selectedTemplate,
                remark: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
      <Stack
        mt={2}
        columnGap={2}
        direction="row"
        // sx={{ display: selectedTemplate ? "block" : "none" }}
      >
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          disabled={selectedTemplate?.investigationRecordItems ? false : true}
          variant="contained"
          onClick={saveData}
        >
          {selectedReport ? "Update" : "Save"}
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            handleDialogClose();
          }}
        >
          Close
        </Button>
      </Stack>
    </>
  );
};

export default InvestigationRecordForm;
