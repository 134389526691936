import React from "react";
import NoResult from "../../assets/images/noresults.png";
import { Grid, Typography } from "@mui/material";
const NoReport = () => {
  return (
    <>
      <Grid align="center" sx={{ mt: 5, mb: 5 }}>
        <img src={NoResult} alt="No Result" width="150px" />
        <Typography variant="h4" fontWeight={100}>
          There are no reports uploaded for this patient!
        </Typography>
      </Grid>
    </>
  );
};

export default NoReport;
