import { useSelector } from "react-redux";

// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import doctorMenuItems from "menu-items/doctorMenuItems";
import frontDeskMenuItems from "menu-items/frontDeskMenuItems";
import technicianMenuItems from "menu-items/technicianMenuItems";
import ipdStaffMenuItems from "menu-items/ipdStaffMenuItems";
import adminMenuItems from "menu-items/adminMenuItems";
import accountsMenuItems from "menu-items/accountsMenuItems";
import patientMenuItems from "menu-items/patientMenuItems";
import pharmacyManagementMenuItems from "menu-items/pharmacyManagementMenuItems";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { role } = useSelector((state) => state.userReducer);
  // const role = 1;
  let menuItemsTobAdded = [];
  if (role == 0) {
    menuItemsTobAdded = doctorMenuItems;
  } else if (role == 1) {
    menuItemsTobAdded = frontDeskMenuItems;
  } else if (role == 2 || role == 8) {
    menuItemsTobAdded = technicianMenuItems;
  } else if (role == 3) {
    menuItemsTobAdded = ipdStaffMenuItems;
  } else if (role == 4) {
    menuItemsTobAdded = accountsMenuItems;
  } else if (role == 5) {
    menuItemsTobAdded = adminMenuItems;
  } else if (role == 6) {
    menuItemsTobAdded = pharmacyManagementMenuItems;
  } else if (role == 7) {
    menuItemsTobAdded = patientMenuItems;
  }

  const navItems = menuItemsTobAdded.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
