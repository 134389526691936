import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useInvestigationTempletsMasterService = () => {
  const axiosAuth = useAxiosAuth();

  const getInvestigationsTempletsMasterList = async () => {
    try {
      const response = await axiosAuth.get(`/api/InvestigationTemplets`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationsTempletsMasterById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/InvestigationTemplets/${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addInvestigationsTempletsMaster = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/InvestigationTemplets`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateInvestigationsTempletsMaster = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationTemplets?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteInvestigationsTempletsMaster = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/InvestigationTemplets?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    getInvestigationsTempletsMasterList,
    getInvestigationsTempletsMasterById,
    addInvestigationsTempletsMaster,
    updateInvestigationsTempletsMaster,
    deleteInvestigationsTempletsMaster,
  };
};

export default useInvestigationTempletsMasterService;
