import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useViabilityReportService = () => {
  const axiosAuth = useAxiosAuth();

  const addViabilityReport = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/ViabilityScans`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getViabilityReportByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/ViabilityScans/by-patient-id?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getVaibilityReportByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(`/api/ViabilityScans/${reportId}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateViabilityReport = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/ViabilityScans?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteViabilityRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/ViabilityScans/delete?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addViabilityReport,
    getViabilityReportByPatientId,
    getVaibilityReportByScanReportId,
    updateViabilityReport,
    deleteViabilityRecord,
  };
};

export default useViabilityReportService;
