import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, Card } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../../../services/apiBase";
import useGrowthUltraSoundScanPhotoService from "services/useGrowthultrasoundReportPhotoServices";
import DeleteDialog from "./DeleteDialog";
import useSonographyReportPhotoService from "services/useSonographyReportPhoto";
import useViabilityReportPhotoService from "services/useViabilityReportPhotos";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AlertMessage from "ui-component/AlertMessage";
import useLaproscopyPhotosServices from "services/useLaproscopyPhotosServices";
import useHysterolaproscopyPhotosServices from "services/useHysterolaproscopyPhotosServices";
import useLummellaPhotosServices from "services/useLummellaPhotosServices";
import { useSearchParams } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const PhotoListComponent = ({
  uploadPhotoList,
  photoList,
  getScanPhotos,
  name,
  setUploadPhotoList,
}) => {
  const [openDeletePhotoForServer, setOpenDeletePhotoServer] = useState(false);
  const [openDeletePhoto, setOpenDeletePhoto] = useState(false);

  //photo id or index
  const [photiId, setPhotoId] = useState();

  const [selectedPhototId, setSelectedPhotoId] = useState();
  const [opendeleteTost, setOpenDeleteTost] = useState(false);
  const [strImages, setStrImages] = useState([]);
  //serverImages
  const [serverImage, setServerImage] = useState();
  //call services here to delete photo or call get scan method of parent
  const { deleteLaproscopyPhoto } = useLaproscopyPhotosServices();
  const { deleteHysterolaproscopyPhoto } = useHysterolaproscopyPhotosServices();
  const { deleteLummellaPhoto } = useLummellaPhotosServices();
  // const handleDelete = async () => {
  //   setOpenDeletePhoto(true);
  //   if (name.toLowerCase() == "laproscopy") {
  //     const reponse = await deleteLaproscopyPhoto(selectedPhototId.id);
  //     setOpenDeleteTost(true);
  //   } else if (name.toLowerCase() == "hysterolaproscopy") {
  //     const reponse = await deleteHysterolaproscopyPhoto(selectedPhototId.id);
  //     setOpenDeleteTost(true);
  //   } else if (name.toLowerCase() == "lummella") {
  //     const reponse = await deleteLummellaPhoto(selectedPhototId.id);
  //     setOpenDeleteTost(true);
  //   }
  //   getScanPhotos();
  // };
  const handleClose = () => {
    setOpenDeletePhoto(false);
  };
  const handleCloseServer = () => {
    setOpenDeletePhotoServer(false);
  };
  useEffect(() => {
    if (uploadPhotoList.length > 0) {
      tobase64Handler(uploadPhotoList);
    }
    if (photoList.length > 0) {
      Array.isArray(photoList) && setServerImage([...photoList]);
    }
  }, [photoList, uploadPhotoList]);
  const tobase64Handler = async (arr) => {
    const filePathsPromises = [];
    arr &&
      Array.from(arr).forEach((file) => {
        filePathsPromises.push(toBase64(file));
      });
    const filePaths = await Promise.all(filePathsPromises);

    setStrImages(filePaths);
  };
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  useEffect(() => {
    if (opendeleteTost) {
      setTimeout(() => {
        setOpenDeleteTost(false);
      }, 500);
    }
  }, [opendeleteTost]);
  const handleDeletePhoto = (index) => {
    const imagArr = [...uploadPhotoList];
    imagArr.splice(index, 1);

    setUploadPhotoList(imagArr);
    const newarr = strImages.splice(index, 1);
    setStrImages(newarr);
  };
  const handDeletePhtotFromServer = async (id) => {
    if (name.toLowerCase() == "laproscopy") {
      const reponse = await deleteLaproscopyPhoto(id);
      setOpenDeleteTost(true);
    } else if (name.toLowerCase() == "hysterolaproscopy") {
      const reponse = await deleteHysterolaproscopyPhoto(id);
      setOpenDeleteTost(true);
    } else if (name.toLowerCase() == "lummella") {
      const reponse = await deleteLummellaPhoto(id);
      setOpenDeleteTost(true);
    }
    getScanPhotos();
  };
  return (
    <>
      <Grid container spacing={2}>
        {Array.isArray(serverImage) &&
          serverImage.map((photo, index) => {
            return (
              <Grid key={index} item sm={3} xs={6}>
                <Card>
                  <img
                    src={
                      // photo
                      BASE_URL + "/Documents/" + photo.filePath
                    }
                    alt="img"
                    width="100%"
                    height="200px"
                  />
                  <IconButton
                    sx={{ marginX: 1 }}
                    size="small"
                    color="error"
                    //when photos add first time
                    onClick={
                      () => {
                        setOpenDeletePhotoServer(true);
                        setPhotoId(photo.id);
                      }
                      //   () => {
                      //   handDeletePhtotFromServer(photo.id);
                      // }
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
                <Dialog
                  open={openDeletePhotoForServer}
                  onClose={handleCloseServer}
                  fullWidth
                >
                  <DialogTitle>Delete Photo</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete the Photo for
                      <span style={{ color: "#E90000" }}>
                        {` ${name}`} Photo
                      </span>
                      ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handDeletePhtotFromServer(photiId);
                        handleCloseServer();
                      }}
                      variant="contained"
                    >
                      Yes
                    </Button>
                    <Button onClick={handleCloseServer} variant="outlined">
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
                <AlertMessage
                  message={"Report deleted succesfully!!"}
                  opendeleteTost={opendeleteTost}
                />
              </Grid>
            );
          })}
        {strImages.map((photo, index) => {
          return (
            <Grid key={index} item sm={3} xs={6}>
              <Card>
                <img
                  src={
                    photo
                    // BASE_URL + "/GrowthUltrasoundScanPhotos/" + data.filePath
                  }
                  alt="img"
                  width="100%"
                  height="200px"
                />
                <IconButton
                  sx={{ marginX: 1 }}
                  size="small"
                  color="error"
                  //when photos add first time
                  onClick={() => {
                    setPhotoId(index);
                    setOpenDeletePhoto(true);
                    // handleDeletePhoto(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Card>
              <Dialog open={openDeletePhoto} onClose={handleClose} fullWidth>
                <DialogTitle>Delete Photo</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete the Photo for
                    <span style={{ color: "#E90000" }}>
                      {" "}
                      {` ${name}`} Photo
                    </span>
                    ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleDeletePhoto(photiId);
                      handleClose();
                    }}
                    variant="contained"
                  >
                    Yes
                  </Button>
                  <Button onClick={handleClose} variant="outlined">
                    No
                  </Button>
                </DialogActions>
              </Dialog>
              <AlertMessage
                message={"Report deleted succesfully!!"}
                opendeleteTost={opendeleteTost}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PhotoListComponent;
