import React, { useContext, useEffect, useState } from "react";
import PrintReportContext from "./ReportContext";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import ReportSingleComponent from "./ReportSingleComponent";
import { convertDateToLocal } from "utils/DateOperations";
import useSonographyReportPhotoService from "services/useSonographyReportPhoto";
import SonographyPhotos from "./SonographyPhotos";

const ReportCardData = ({ id, printStatus }) => {
  const [reportPhoto, setReportPhotos] = useState();
  const { getSonographyPhotoByScanReportId } =
    useSonographyReportPhotoService();
  const {
    reportdata,
    reportName,
    reportId,
    doctorsdata,
    patientname,
    theme,
    isSmallScreen,
  } = useContext(PrintReportContext);
  useEffect(() => {
    if (reportdata) {
      getSonographyPhotoByScanReportId(reportdata.id)
        .then((response) => {
          setReportPhotos(response.data.sonographyPhotos);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reportdata]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Impression"
            data={reportdata?.impression}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Impression Comment"
            data={reportdata?.impressionComment}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Pregnancy"
            data={`${reportdata?.pregnancy1Display}, ${reportdata?.pregnancy2Display}, ${reportdata?.pregnancy3Display}`}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label=" Lie & Presentation"
            data={`${reportdata?.lieAndPresentation1Display}, ${reportdata?.lieAndPresentation2Display}`}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal Movement"
            data={reportdata?.fetalMovementDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal Cardiac Activity"
            data={reportdata?.fetalCardiacActivity}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal Cardiac Activity Value"
            data={
              reportdata?.fetalCardiacActivityValue != 0
                ? `${reportdata?.fetalCardiacActivityValue} bpm`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label="HC"
            data={reportdata?.hc != 0 ? `${reportdata?.hc} cm` : "---"}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="BPD"
            data={reportdata?.bpd != 0 ? `${reportdata?.bpd} cm` : "---"}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="AC"
            data={reportdata?.ac != 0 ? `${reportdata?.ac} cm` : "---"}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Femur Length"
            data={
              reportdata?.femurLength != 0
                ? `${reportdata?.femurLength} cm`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Estimated Fetal Weight"
            data={
              reportdata?.estimatedFetalWeight != 0
                ? `${reportdata?.estimatedFetalWeight} gms`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Liquor"
            data={reportdata?.liquorDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Placenta"
            data={`${reportdata?.placenta1Display}, ${reportdata?.placenta2Display}, ${reportdata?.placenta3Display}`}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {" "}
          <ReportSingleComponent
            id={id}
            label="Avg Ultrasound Age Week"
            data={
              reportdata?.avgUltrasoundAgeWeek != 0
                ? `${reportdata?.avgUltrasoundAgeWeek} wk`
                : "---"
            }
          />{" "}
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Avg Ultrasound Age Days"
            data={
              reportdata?.avgUltrasoundAgeDays != 0
                ? `${reportdata?.avgUltrasoundAgeDays} d`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="EDD By USG"
            data={convertDateToLocal(reportdata?.eddByUSG)}
          />{" "}
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="EDD By LMP"
            data={convertDateToLocal(reportdata?.eddByLMP)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="GA By LMP"
            data={reportdata?.gaByLMP}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Cervix Length"
            data={
              reportdata?.cervixLength != 0
                ? `${reportdata?.cervixLength} cm`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Scar Thickness"
            data={
              reportdata?.scarThickness != 0
                ? `${reportdata?.scarThickness} cm`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Effacement Os Closed"
            data={reportdata?.effacementOsClosed}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Effacement Percentage"
            data={
              reportdata?.effacementPercentage != 0
                ? `${reportdata?.effacementPercentage} %`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Doppler Evaluation"
            data={reportdata?.dopplerEvaluationDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent id={id} label="CPR" data={reportdata?.cpr} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal Umblical Artery PI"
            data={
              reportdata?.fetalUmblicalArteryPI != 0
                ? `${reportdata?.fetalUmblicalArteryPI} PI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal Umblical Artery RI"
            data={
              reportdata?.fetalUmblicalArteryRI != 0
                ? `${reportdata?.fetalUmblicalArteryRI} RI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal Umblical Artery S/D"
            data={
              reportdata?.fetalUmblicalArteryS_D != 0
                ? `${reportdata?.fetalUmblicalArteryS_D} S/D`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal MCAPI"
            data={
              reportdata?.fetalMCAPI != 0
                ? `${reportdata?.fetalMCAPI} PI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal MCARI"
            data={
              reportdata?.fetalMCARI != 0
                ? `${reportdata?.fetalMCARI} RI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Fetal MCAS_D"
            data={
              reportdata?.fetalMCAS_D != 0
                ? `${reportdata?.fetalMCAS_D} S/D`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="RT UT Artery PI"
            data={
              reportdata?.rtUtArteryPI != 0
                ? `${reportdata?.rtUtArteryPI} PI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="RT UT Artery RI"
            data={
              reportdata?.rtUtArteryRI != 0
                ? `${reportdata?.rtUtArteryRI} RI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="LT UT Artery PI"
            data={
              reportdata?.ltUtArteryPI != 0
                ? `${reportdata?.ltUtArteryPI} PI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="LT UT Artery RI"
            data={
              reportdata?.ltUtArteryRI != 0
                ? `${reportdata?.ltUtArteryRI} RI`
                : "---"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="UT Artery"
            data={reportdata?.utArtery}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Manning Score"
            data={reportdata?.manningScore}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="Manning Score Display"
            data={reportdata?.manningScoreDisplay}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="General Impression"
            data={reportdata?.generalImpression}
          />{" "}
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportSingleComponent
            id={id}
            label="General Impression"
            data={reportdata?.generalImpression}
          />{" "}
        </Grid>

        {printStatus == false && (
          <Grid item xs={12} sm={12}>
            <SonographyPhotos label="Sonography Photos" photos={reportPhoto} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReportCardData;
