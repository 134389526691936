import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import NoPhotosComponent from "../../../../ui-component/formcomponent/NoPhoto";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
// import PhotoListComponent from "./PhotoList";
import { useSearchParams } from "react-router-dom";
import useLaporoscopyServices from "services/useLaporoscopyServices";
import useLaproscopyPhotosServices from "services/useLaproscopyPhotosServices";
import useHysterolaproscopyPhotosServices from "services/useHysterolaproscopyPhotosServices";
import useLummellaPhotosServices from "services/useLummellaPhotosServices";
import PhotoListComponent from "./PhotoListComponent";

const UploadPhoto = ({ name, takePhotoList }) => {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [photoList, setPhotoList] = useState([]);
  // const [strImage, setStrImage] = useState([]);
  const [searchParams] = useSearchParams();
  const [uploadPhotoList, setUploadPhotoList] = useState([]);

  //get photos from  api on report card id
  const { getLaproscopyPhotoByScanReportId } = useLaproscopyPhotosServices();
  const { getHysterolaproscopyPhotoByScanReportId } =
    useHysterolaproscopyPhotosServices();
  const { getLummellaPhotoByScanReportId } = useLummellaPhotosServices();

  const getScanPhotos = () => {
    if (name.toLowerCase() == "laproscopy") {
      getLaproscopyPhotoByScanReportId(searchParams.get("reportid"))
        .then((resp) => {
          setPhotoList(resp.data?.laproscopyPhotos);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (name.toLowerCase() == "hysterolaproscopy") {
      getHysterolaproscopyPhotoByScanReportId(searchParams.get("reportid"))
        .then((resp) => {
          setPhotoList(resp.data?.hysterolaproscopyPhotos);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (name.toLowerCase() == "lummella") {
      getLummellaPhotoByScanReportId(searchParams.get("reportid"))
        .then((resp) => {
          setPhotoList(resp.data?.lummella);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (searchParams.get("reportid")) {
      getScanPhotos();
    }
  }, []);
  const handlePhotoUpload = (e) => {
    const { files } = e.target;

    setUploadPhotoList([...uploadPhotoList, ...files]);
  };
  useEffect(() => {
    if (Array.isArray(uploadPhotoList) && uploadPhotoList.length > 0)
      takePhotoList(uploadPhotoList);
  }, [uploadPhotoList]);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item sm={6}>
          <Typography align="left" variant="h4">
            Upload Photo
          </Typography>
        </Grid>
        <Grid item sm={6} align="right">
          <label htmlFor="btn-upload-photo">
            <input
              id="btn-upload-photo"
              name="btn-upload-1"
              style={{ display: "none" }}
              type="file"
              multiple
              accept=".jpg, .png, .jpeg"
              disabled={uploadingPhoto}
              onChange={handlePhotoUpload}
            />
            <LoadingButton
              loading={uploadingPhoto}
              component="span"
              size="small"
              variant="contained"
            >
              {uploadingPhoto ? "Uploading..." : "Upload Photo"}
            </LoadingButton>
          </label>
        </Grid>
      </Grid>
      {photoList.length > 0 || uploadPhotoList.length > 0 ? (
        <PhotoListComponent
          uploadPhotoList={uploadPhotoList}
          name={name}
          photoList={photoList}
          setUploadPhotoList={setUploadPhotoList}
          getScanPhotos={getScanPhotos}
        />
      ) : (
        <NoPhotosComponent />
      )}
    </>
  );
};

export default UploadPhoto;
