import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { axiosOpen } from "./apiBase";
import { handleResponse, handleError } from "./response";

const useAuthenticationService = () => {
  const axiosAuth = useAxiosAuth();

  const changePassword = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/Authentication/change-password`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const resetPassword = async (patientId) => {
    try {
      const response = await axiosAuth.post(
        `/api/Authentication/reset-password?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const sendOtp = async (email) => {
    try {
      const response = await axiosOpen.post(
        `/api/Authentication/send-password-reset-otp?email=${email}`
      );

      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const verifyOtp = async (otp, email) => {
    try {
      const response = await axiosOpen.post(
        `/api/Authentication/verify-otp?otp=${otp}&email=${email}&isForgotPassword=true`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const login = async (email, password) => {
    try {
      let request = {
        userName: email,
        password: password,
      };
      const response = await axiosOpen.post(
        "/api/Authentication/login",
        request
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const signUp = async (values) => {
    try {
      const response = await axiosOpen.post(
        `/api/Authentication/signup`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return { changePassword, sendOtp, verifyOtp, login, signUp, resetPassword };
};

export default useAuthenticationService;
