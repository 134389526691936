import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { useDialogOpenration } from "context/DialogContex";

const DialogComponent = ({ name, children }) => {
  const { openDialog, handleDialogClose } = useDialogOpenration();
  const defaultName = "";

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography align="center" variant="h4" paddingLeft={3}>
            {name ? name : defaultName}
          </Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default DialogComponent;
