// assets
import {
  IconDashboard,
  IconBrandChrome,
  IconHelp,
  IconUsers,
  IconUser,
  IconRoute,
  IconLayoutKanban,
  IconReport,
  IconSettings,
  IconBed,
  IconFileInvoice,
  IconCertificate,
  IconCalendarTime,
  IconReportSearch,
  IconCurrencyRupee,
  IconTestPipe,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconBrandChrome,
  IconHelp,
  IconUsers,
  IconUser,
  IconRoute,
  IconLayoutKanban,
  IconReport,
  IconSettings,
  IconBed,
  IconFileInvoice,
  IconCurrencyRupee,
  IconCertificate,
  IconCalendarTime,
  IconReportSearch,
  IconTestPipe,
};

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const doctorMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "patients",
        title: "Patients",
        type: "item",
        url: "/patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
      {
        id: "filtered-patients",
        title: "Filtered Patients",
        type: "item",
        url: "/filtered-patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
    ],
  },

  {
    id: "doctor-main-pages",
    type: "group",
    children: [
      {
        id: "my-appointments",
        title: "My Appointments",
        type: "item",
        icon: icons.IconCalendarTime,
        url: "/my-appointments",
        breadcrumbs: false,
      },
      {
        id: "daycare-overview",
        title: "Day Care Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/daycare-overview",
        breadcrumbs: false,
      },
      {
        id: "ipd-overview",
        title: "IPD Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/ipd-overview",
        breadcrumbs: false,
      },
      // {
      //   id: "ipd",
      //   title: "IPD",
      //   type: "collapse",
      //   icon: icons.IconBed,
      //   breadcrumbs: false,
      //   children: [
      //     {
      //       id: "ipd-overview",
      //       title: "IPD Overview",
      //       type: "item",
      //       url: "/ipd-overview",
      //       breadcrumbs: false,
      //     },
      //   ],
      // },
      {
        id: "billing",
        title: "Billing",
        type: "collapse",
        icon: icons.IconFileInvoice,
        breadcrumbs: false,
        children: [
          {
            id: "advance-payments",
            title: "Advance Payments",
            type: "item",
            url: "/advance-payments",
            breadcrumbs: false,
          },
          {
            id: "ipd-billing",
            title: "IPD Bills",
            type: "item",
            url: "/billing",
            breadcrumbs: false,
          },
          {
            id: "opdReceipts",
            title: "OPD Bills/Receipts",
            type: "item",
            url: "/opd-receipts",
            breadcrumbs: false,
          },
          {
            id: "discharge-cards",
            title: "Discharge Cards",
            type: "item",
            url: "/discharge-cards",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "operating-expenses",
        title: "Expenses",
        type: "item",
        url: "/operating-expenses",
        icon: icons.IconCurrencyRupee,
        breadcrumbs: false,
      },
      {
        id: "certificates",
        title: "Certificates",
        type: "item",
        url: "/certificates",
        icon: icons.IconCertificate,
        breadcrumbs: false,
      },
      // {
      //   id: "appointment-history",
      //   title: "Appointment History",
      //   type: "item",
      //   url: "/appointment-history",
      //   icon: icons.IconCertificate,
      //   breadcrumbs: false,
      // },
      // {
      //   id: "appointment-booking",
      //   title: "Appointment Booking",
      //   type: "item",
      //   url: "/appointment-booking",
      //   icon: icons.IconCertificate,
      //   breadcrumbs: false,
      // },
      // {
      //   id: "basic-profile",
      //   title: "Basic Profile",
      //   type: "item",
      //   url: "/basic-profile",
      //   icon: icons.IconCertificate,
      //   breadcrumbs: false,
      // },
      // {
      //   id: "pharmacy-management",
      //   title: "Pharmacy Management",
      //   type: "item",
      //   url: "/pharmacy-management",
      //   icon: icons.IconCertificate,
      //   breadcrumbs: false,
      // },

      {
        id: "investigations",
        title: "Investigations",
        type: "item",
        url: "/investigations",
        icon: icons.IconTestPipe,
        breadcrumbs: false,
      },
    ],
  },
  {
    id: "setting-pages",
    type: "group",
    children: [
      {
        id: "setting-pages",
        title: "Settings",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "bill-items",
            title: "IPD Bill Items",
            type: "item",
            url: "/bill-items",

            breadcrumbs: false,
          },
          {
            id: "opd-receipt-item",
            title: "OPD Bill Items",
            type: "item",
            url: "/opd-receipt-item-master",

            breadcrumbs: false,
          },
          {
            id: "drug-items",
            title: "Prescription Drug Master",
            type: "item",
            url: "/drug-items",

            breadcrumbs: false,
          },
          {
            id: "prescription-templates",
            title: "Prescription Templates",
            type: "item",
            url: "/prescription-templates",

            breadcrumbs: false,
          },
          {
            id: "investigation-item",
            title: "Investigation Master",
            type: "item",
            url: "/investigation-items",
            breadcrumbs: false,
          },
          {
            id: "investigation-templets",
            title: "Investigation Templates",
            type: "item",
            url: "/investigation-templets",
            breadcrumbs: false,
          },
          // {
          //   id: "advice-item",
          //   title: "Advice Items",
          //   type: "item",
          //   url: "/advice-items",

          //   breadcrumbs: false,
          // },
          // {
          //   id: "clinicalFinding-item",
          //   title: "Clinical Finding Items",
          //   type: "item",
          //   url: "/clinicalFinding-items",

          //   breadcrumbs: false,
          // },
          {
            id: "operation-types",
            title: "Operation Types",
            type: "item",
            url: "/operation-types",

            breadcrumbs: false,
          },
          {
            id: "expense-category-item",
            title: "Expenses Category",
            type: "item",
            url: "/expense-category-item-master",

            breadcrumbs: false,
          },
          {
            id: "employees",
            title: "Employees",
            type: "item",
            url: "/users",

            breadcrumbs: false,
          },
          {
            id: "external-staff",
            title: "External Staff",
            type: "item",
            url: "/external-staff",

            breadcrumbs: false,
          },
          {
            id: "appointment-slot",
            title: "Appointment Slot",
            type: "item",
            url: "/appointment-slot",

            breadcrumbs: false,
          },
          {
            id: "refferedBy",
            title: "Referal Doctors",
            type: "item",
            url: "/refferedBy",

            breadcrumbs: false,
          },
          {
            id: "additional-treatment-item",
            title: "Additional Treatments",
            type: "item",
            url: "/settings/additional-treatment",
            breadcrumbs: false,
          },
          {
            id: "general-settings",
            title: "General settings",
            type: "item",
            url: "/general-settings",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },

  {
    id: "report-pages",
    type: "group",
    children: [
      {
        id: "reports-pages",
        title: "Reports",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "opd-reports",
            title: "OPD Billing",
            type: "item",
            //url: "/opd-reports",
            url: "/revenue-report",

            breadcrumbs: false,
          },
          {
            id: "investigation-reports",
            title: "Investigations",
            type: "item",
            url: "/investigation-reports",
            breadcrumbs: false,
          },
          {
            id: "ipd-reports",
            title: "IPD Billing",
            type: "item",
            url: "/ipd-reports",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default doctorMenuItems;
