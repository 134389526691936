// assets
import { IconTestPipe } from "@tabler/icons";

// constant
const icons = {
  IconTestPipe,
};

// ==============================|| TECHNICIAN MENU ITEMS ||============================== //

const technicianMenuItems = [
  {
    id: "lab-main-pages",
    type: "group",
    children: [
      {
        id: "investigations",
        title: "Investigations",
        type: "item",
        icon: icons.IconTestPipe,
        url: "/investigations",
        breadcrumbs: false,
      },
    ],
  },
];

export default technicianMenuItems;
