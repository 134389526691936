import React, { useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/userSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import { LoadingButton } from "@mui/lab";
import useAuthenticationService from "services/useAuthenticationService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  oldPassword: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  newPassword: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .max(50, "Must be 50 characters or less ")
    .when("newPassword", {
      is: (newPassword) =>
        newPassword && newPassword.length > 0 ? true : false,
      then: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Password doesn't match"),
    }),
});

const ChangePassword = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId, userName, roleDisplay } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();
  const { onSave, onCancel, usersId } = props;
  const { changePassword } = useAuthenticationService();
  const [openToast, setOpenToast] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };
  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(setUser({ userId: 0, profileId: 0, displayName: "", role: "" }));
    navigate("/login");
  };
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      let data = {
        userId: usersId,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      changePassword(data)
        .then((response) => {
          if (response.status === 200) {
            setSubmitting(false);
            if (response.data.status === true) {
              //onSave();
              alert("Password changed successfully! You must sign in again.");
              handleLogout();
            } else {
              alert(response.data);
            }
          } else {
            alert(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });

      // axios
      //     .post('/api/Users/CheckEmailExists?email=' + values.email)
      //     .then((response) => {
      //         if (response.status === 200) {
      //             if (response.data == false) {
      //                 SignUp(values);
      //             } else {
      //                 alert('The email: ' + values.email + 'already exists, please sign in with this email instead.');
      //                 setSubmitting(false);
      //             }
      //         }
      //     })
      //     .catch(function (error) {
      //         console.log(error);
      //         setSubmitting(false);
      //     });
    },
  });

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ marginX: "auto", alignItems: "center" }}
      >
        <PasswordIcon fontSize="medium" color="primary" />
        <Typography variant="h4">Change Password</Typography>
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack spacing={2}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Old Password"
            name="oldPassword"
            type="password"
            fullWidth
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            sx={{ mb: 2 }}
          ></TextField>
          <TextField
            label="New Password"
            name="newPassword"
            type="password"
            fullWidth
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            sx={{ mb: 2 }}
          ></TextField>
          <TextField
            label="Confirm New Passowrd"
            name="confirmPassword"
            type="password"
            fullWidth
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            sx={{ mb: 2 }}
          ></TextField>
          <Divider sx={{ marginY: 2 }} />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" type="submit" disabled={submitting}>
              Change Password
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Stack>
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%", backgroundColor: theme.palette.success.dark }}
        >
          Password changed succesfully !!!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePassword;
