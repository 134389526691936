import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import PrintReportContext from "./ReportContext";
import { BASE_URL } from "services/apiBase";
const SonographyPhotos = ({ label, photos }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{label}:</Typography>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            {Array.isArray(photos) &&
              photos.map((data) => {
                return (
                  <Grid item xs={4} sx={{ mt: 1 }}>
                    <img
                      src={BASE_URL + "/Documents/" + data.filePath}
                      alt="img"
                      width="100%"
                      height="200px"
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SonographyPhotos;
