import React, { memo, useCallback, useState, useEffect, Fragment } from "react";
import {
  Grid,
  Stack,
  Card,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
  Snackbar,
  DialogContent,
  Dialog,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteDialog from "./DeleteDialog";
import MuiAlert from "@mui/material/Alert";

import useGrowthUltraSoundScanPhotoService from "services/useGrowthultrasoundReportPhotoServices";
import AlertMessage from "ui-component/AlertMessage";
import useSonographyReportPhotoService from "services/useSonographyReportPhoto";
import useViabilityReportPhotoService from "services/useViabilityReportPhotos";

//ReportName COntext
import ReportNameContext, {
  ReportContextProvider,
} from "./ExternalReportContext";
import PrintREport from "./report-print";
import DialogComponent from "ui-component/dialog/DialogComponent";
import Report from "./report-print";
import { useDialogOpenration } from "context/DialogContex";
import { convertDateToLocal } from "utils/DateOperations";
import { useSelector } from "react-redux";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ReportCard = ({ reportData, loadData }) => {
  const { role } = useSelector((state) => state.userReducer);
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedReport, setSelectedReport] = useState();
  const [reportName, setReportName] = useState();

  const [openDelete, setOpenDelete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [reportFile, setReportFile] = useState();
  const [opentoast, setOpenToast] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { addGrowthAltraSoundScanReportPhoto } =
    useGrowthUltraSoundScanPhotoService();
  const { addSonographyReportPhoto } = useSonographyReportPhotoService();
  const { addViabilityReportPhoto } = useViabilityReportPhotoService();
  useEffect(() => {
    if (reportFile == undefined) {
      return;
    }

    if (selectedReport && reportFile && reportName) {
      const fd = new FormData();
      setUploading(true);
      fd.append("file", reportFile);
      //   if (reportName.toLowerCase() == "sonography") {
      addSonographyReportPhoto(selectedReport?.id, fd)
        .then((response) => {
          if (response.status === 200) {
            setOpenToast(true);
            setUploading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setUploading(false);
        });
    }
  }, [reportFile]);
  const handleCancelDelete = () => {
    setOpenDelete(false);
  };
  const handleEdit = (data, type) => {
    navigate(`/viability-form?reportid=${data.id}&type=${type}`);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleUpload = (data, name) => {
    setReportName(name);
    setSelectedReport(data);
  };
  const viewReport = (id, reportname) => {
    if (!id && !reportname) {
      return;
    }
    window.open(`/print-report?reportid=${id}&name=${reportname}`, "_blank");
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Grid container marginTop={1} spacing={2}>
        {reportData.map((row, index) => (
          <Fragment key={index}>
            <Grid item xs={12} sm={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  padding: 2,
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography fontWeight="bold">
                      {row.typeDisplay} Report
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography fontWeight="bold">Report Date</Typography>
                    <Typography>{convertDateToLocal(row.date)}</Typography>
                  </Stack>
                  {uploading && row?.id == selectedReport?.id ? (
                    <Stack spacing={1}>
                      <Typography>Uploading file... </Typography>
                      <LinearProgress />
                    </Stack>
                  ) : (
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <>
                        {/* <label htmlFor="btn-upload">
                          <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: "none" }}
                            type="file"
                            multiple={false}
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            onChange={(e) => {
                              // setSelectedReport(d);
                              setReportFile(e?.target?.files[0]);
                            }}
                          />

                          <Tooltip
                            title={
                              row.isFileUploaded
                                ? "Change Report File"
                                : "Upload Report"
                            }
                          >
                            <IconButton component="span" color="primary">
                              <UploadFileIcon
                                onClick={() => {
                                  handleUpload(row, row?.type);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </label> */}
                        <Tooltip title="View Report">
                          <IconButton
                            // disabled={data.isFileUploaded == false ? true : false}
                            onClick={() => {
                              setOpenDialog(true);
                              setSelectedReport(row.id);
                              // viewReport(row.id, row.type);
                            }}
                            color="primary"
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Edit Report Details"
                          sx={{ display: role == 7 ? "none" : "block" }}
                        >
                          <IconButton
                            onClick={() => {
                              handleEdit(row, row.type);
                            }}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Delete"
                          sx={{ display: role == 7 ? "none" : "block" }}
                        >
                          <IconButton
                            onClick={() => {
                              setSelectedReport(row);
                              setReportName(row.type);
                              setOpenDelete(true);
                            }}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    </Stack>
                  )}
                </Stack>
              </Card>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <AlertMessage message="Report Uploaded succesfully!!" />
      <DeleteDialog
        openDelete={openDelete}
        handleCancelDelete={handleCancelDelete}
        selectedReport={selectedReport}
        reportName={reportName}
        loadData={loadData}
        componentname="card"
      />
      <Dialog open={openDialog} onClose={handleDialogClose} fullScreen>
        <DialogContent>
          <Report
            selectedReport={selectedReport}
            handleDialogClose={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(ReportCard);
