import React from "react";
import { Grid, Typography, Stack, Button } from "@mui/material";
import ReportCard from "../ReportCard";
import NoReport from "ui-component/formcomponent/NoReport";

const FirstTrimester = ({
  firstTrimesterReport,
  setFirstTrimesterReport,
  firstTrimesterData,
  loadData,
}) => {
  return (
    <>
      {" "}
      <Grid xs={12}>
        <Typography variant="h4" color="primary" mt={2}>
          First Trimester
        </Typography>
        <Stack mt={2} columnGap={2} direction="row">
          <Button
            variant={firstTrimesterReport == 1 ? "contained" : "outlined"}
            onClick={() => {
              setFirstTrimesterReport(1);
            }}
          >
            Viability scan
          </Button>
          <Button
            variant={firstTrimesterReport == 2 ? "contained" : "outlined"}
            onClick={() => {
              setFirstTrimesterReport(2);
            }}
          >
            NT Scan
          </Button>
        </Stack>
        {Array.isArray(firstTrimesterData) && firstTrimesterData.length > 0 ? (
          <ReportCard reportData={firstTrimesterData} loadData={loadData} />
        ) : (
          <>
            <NoReport />
          </>
        )}
      </Grid>
    </>
  );
};

export default FirstTrimester;
