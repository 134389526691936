import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  Box,
  CardHeader,
  Divider,
} from "@mui/material";
import FormTextInput from "ui-component/formcomponent/FormTextInput";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import NoPhotosComponent from "ui-component/formcomponent/NoPhoto";
import useHysterolaproscopyServices from "services/useHysterolaproscopyServices";
import UploadPhoto from "../UploadPhoto";
import useHysterolaproscopyPhotosServices from "services/useHysterolaproscopyPhotosServices";
import { convertDateToLocalReverse } from "utils/DateOperations";

const initialValue = {
  patientId: 0,
  userId: 0,
  cervix: 0,
  cervialCanal: 0,
  uterineCavity: 0,
  biCornue: 0,
  endometrium: 0,
  dischargeDate: convertDateToLocalReverse(new Date()),
  comments: "",
  advice: "",
};
const HysterolaproscopyForm = () => {
  const navigate = useNavigate();
  //current logged user
  const { userId: UserId } = useSelector((state) => state.userReducer);

  //search prameters of url
  const [searchParams] = useSearchParams();

  //state for store photo file local machine
  const [uploadphotoList, setUpoloadPhotoList] = useState();
  const [loading, setLoading] = useState(false);

  const {
    addHysterolaproscopyReport,
    updateHysterolaproscopyReport,
    getHysterolaproscopyReportByReportId,
  } = useHysterolaproscopyServices();
  const { addHysterolaproscopyReportPhoto } =
    useHysterolaproscopyPhotosServices();

  const { setValue, getValues, watch, reset, control, handleSubmit } = useForm({
    defaultValues: initialValue,
    mode: "all",
  });

  useEffect(() => {
    if (!UserId) {
      return;
    }
    setValue("userId", UserId);
    if (searchParams.get("pateintId")) {
      setValue("patientId", searchParams.get("pateintId"));
    }
    if (searchParams.get("reportid")) {
      getHysterolaproscopyReportByReportId(searchParams.get("reportid")).then(
        (result) => {
          if (result.data.dischargeDate) {
            result.data.dischargeDate = result.data.dischargeDate.split("T")[0];
          }
          reset(result.data);
        }
      );
    }
  }, []);
  const handleCancle = () => {
    navigate(`/patient-details?Id=${getValues("patientId")}&tabValue=${2}`);
  };
  console.log("Hypro :", watch());
  const onSubmit = async (data) => {
    setLoading(true);
    if (searchParams.get("reportid")) {
      try {
        const result = await updateHysterolaproscopyReport(
          searchParams.get("reportid"),
          data
        );
        const formData = new FormData();

        Array.isArray(uploadphotoList) &&
          uploadphotoList.map(async (file) => {
            formData.append("file", file);
            try {
              const response = await addHysterolaproscopyReportPhoto(
                searchParams.get("reportid"),
                formData
              );
              setLoading(false);
            } catch (err) {
              console.log(err);
            }
          });

        navigate(`/patient-details?Id=${data.patientId}&tabValue=${2}`);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const result = await addHysterolaproscopyReport(data);
        const reportid = result?.data?.newId;
        setLoading(false);
        const formData = new FormData();

        Array.isArray(uploadphotoList) &&
          uploadphotoList.map(async (file) => {
            formData.append("file", file);
            try {
              const response = await addHysterolaproscopyReportPhoto(
                reportid,
                formData
              );
            } catch (err) {
              console.log(err);
            }
          });

        navigate(`/patient-details?Id=${data.patientId}&tabValue=${2}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const takePhotoList = (photodata) => {
    setUpoloadPhotoList(photodata);
  };
  return (
    <>
      <Card padding={5}>
        <CardHeader
          title={
            <Typography align="center" variant="h3">
              Hysterolaproscopy Report
            </Typography>
          }
        />
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            padding={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={8}>
                  <Typography align="left" variant="h4">
                    Discharge Date
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <FormTextInput
                    control={control}
                    type="date"
                    variant="standard"
                    name="dischargeDate"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextInput
                    control={control}
                    name="comments"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" variant="h4" color="secondary">
                Hysteroscopic Findings
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid xs={8}>
                  <Typography align="left" variant="h4">
                    Cervix
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervix") == 0 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("cervix", 0);
                        }}
                      >
                        Healthy
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervix") == 1 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("cervix", 1);
                        }}
                      >
                        Unhealthy
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid xs={8}>
                  <Typography align="left" variant="h4">
                    Cervial Canal
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervialCanal") == 0
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("cervialCanal", 0);
                        }}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervialCanal") == 1
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("cervialCanal", 1);
                        }}
                      >
                        Abnormal
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid xs={8}>
                  <Typography align="left" variant="h4">
                    Uterine Cavity
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("uterineCavity") == 0
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("uterineCavity", 0);
                        }}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("uterineCavity") == 1
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("uterineCavity", 1);
                        }}
                      >
                        Abnormal
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid xs={8}>
                  <Typography align="left" variant="h4">
                    B/I Cornue
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("biCornue") == 0 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("biCornue", 0);
                        }}
                      >
                        Visible
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("biCornue") == 1 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("biCornue", 1);
                        }}
                      >
                        Not visible
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid xs={8}>
                      <Typography align="left" variant="h4">
                        Endometrium
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            variant={
                              getValues("endometrium") == 0
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setValue("endometrium", 0);
                            }}
                          >
                            Healthy
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            variant={
                              getValues("endometrium") == 1
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setValue("endometrium", 1);
                            }}
                          >
                            Unhealthy
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <Typography align="left" variant="h4">
                    Advice
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormTextInput
                    control={control}
                    name="advice "
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined" sx={{ padding: 1 }}>
                {" "}
                <UploadPhoto
                  name="hysterolaproscopy"
                  takePhotoList={takePhotoList}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                sx={{ margin: 1 }}
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={handleCancle}
                sx={{ margin: 1 }}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
};

export default HysterolaproscopyForm;
